<template>
  <div style="min-height: 750px;" v-if="d_frdWdm">
    <template v-if="d_pageShow">
      <b-row>
        <b-col sm="12" lg="2"></b-col>
        <b-col sm="12" lg="8"></b-col>
        <b-col sm="12" lg="2"></b-col>
      </b-row>
      <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
        <b-col sm="12" md="2" lg="2"> </b-col>
        <b-col sm="12" md="4" lg="4">
          <i class="fa fa-desktop"></i>
          {{ StoreLangTranslation.data['application_settings'][StoreLang] }}
        </b-col>
        <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
        <b-col sm="12" md="2" lg="2">
        </b-col>
      </b-row>
      <b-row style="font-size: 16px;">
        <b-col sm="12" lg="2"></b-col>
        <b-col sm="12" lg="8">
          <template v-if="d_frontendLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template>
            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['general_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['20']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.application_name[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['33']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.are_shop_pages_active">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['33'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row v-if="d_frontendData.are_shop_pages_active === '1'" style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['86']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.market_visiblity_type">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['86'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['100']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.google_analytics_id">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['101']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-textarea v-model="d_frontendData.login_button_name[lang.label]" rows="5"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['102']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.language_change_button_type">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['102'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['98']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.default_language">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['98'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['99']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.language_change_button_visibility">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['99'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['87']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-form-checkbox-group v-model="d_frontendData.language_options" stacked>
                    <b-form-checkbox v-for="(op, op_index) in d_frdWdm['0']['parameters']['87'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['88']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.general_font_select">
                    <option value="">-</option>
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['88'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['89']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.general_font_style">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['90']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.general_font_weight">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['91']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.general_font_size">
                </b-col>
              </b-row>


              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['95']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.general_text_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['61']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.application_frontend_uri">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['62']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.application_backend_uri">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['85']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="6">
                  <div>
                    <input type="text" class="form-control" v-model="d_frontendData.page_favicon">
                  </div>
                  <div v-if="d_frontendData.page_favicon">
                    <img :src="d_frontendData.page_favicon" height="100">
                  </div>
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button variant="outline-success" @click="f_imageUpload('page_favicon')">
                    <i class="fa fa-plus-circle"></i>
                  </b-button>
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button variant="outline-danger" @click="$delete(d_frontendData, 'page_favicon'); $forceUpdate();">
                    <i class="fa fa-minus-circle"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['menu_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['28']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.menu_type">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['28'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['22']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.menu_top_caption[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_background_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_border_bottom_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_box_shadow_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_child_item_background_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_child_item_border_bottom_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['7']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_child_item_text_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_parent_item_background_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_parent_item_border_bottom_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.menu_parent_item_text_color">
                </b-col>
              </b-row>            
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['navbar_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['21']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="6">
                  <input type="text" class="form-control" v-model="d_frontendData.navbar_middle_logo">
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button variant="outline-success" @click="f_imageUpload('navbar_middle_logo')">
                    <i class="fa fa-plus-circle"></i>
                  </b-button>
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button variant="outline-danger" @click="$delete(d_frontendData, 'navbar_middle_logo'); $forceUpdate();">
                    <i class="fa fa-minus-circle"></i>
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['68']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.navbar_background_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['69']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.navbar_application_name_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.navbar_application_name_shadow_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['73']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.navbar_text_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['84']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-textarea v-model="d_frontendData.navbar_flowing_text[lang.label]" rows="5"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['96']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.navbar_font_size">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['97']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.navbar_text_weight">
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['footer_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['71']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.footer_text_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['72']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.footer_background_color">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['83']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.footer_date">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['92']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.footer_text_weight">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['93']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.footer_text_padding">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['94']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="number" class="form-control" v-model="d_frontendData.footer_text_size">
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['page_area_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['70']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="color" class="form-control" v-model="d_frontendData.page_area_text_color">
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['social_media_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['74']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.facebook_link">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['75']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.twitter_link">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['76']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.instagram_link">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['77']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.linkedin_link">
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['wanalyzer_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['29']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.wanalyzer_api_url">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['30']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.wanalyzer_api_username ? 'text' : 'password'" class="form-control" v-model="d_frontendData.wanalyzer_api_username" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.wanalyzer_api_username ? d_showPassword.wanalyzer_api_username = false : d_showPassword.wanalyzer_api_username = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['31']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.wanalyzer_api_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.wanalyzer_api_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.wanalyzer_api_password ? d_showPassword.wanalyzer_api_password = false : d_showPassword.wanalyzer_api_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['32']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.wanalyzer_frontend_url">
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['worganization_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['34']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.worganization_api_url">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['60']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.worganization_user_id">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['35']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.worganization_api_username ? 'text' : 'password'" class="form-control" v-model="d_frontendData.worganization_api_username" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.worganization_api_username ? d_showPassword.worganization_api_username = false : d_showPassword.worganization_api_username = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['36']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.worganization_api_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.worganization_api_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.worganization_api_password ? d_showPassword.worganization_api_password = false : d_showPassword.worganization_api_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['37']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.worganization_frontend_url">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['38']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.worganization_token ? 'text' : 'password'" class="form-control" v-model="d_frontendData.worganization_token" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.worganization_token ? d_showPassword.worganization_token = false : d_showPassword.worganization_token = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['39']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.worganization_organizasyon_id_values">
                </b-col>
              </b-row>            
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['legal_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['40']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.terms_of_service_link[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['41']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.privacy_policy_link[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['mail_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['47']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.mail_api_token_uri">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['48']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.mail_api_token_username ? 'text' : 'password'" class="form-control" v-model="d_frontendData.mail_api_token_username" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.mail_api_token_username ? d_showPassword.mail_api_token_username = false : d_showPassword.mail_api_token_username = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['49']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.mail_api_token_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.mail_api_token_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.mail_api_token_password ? d_showPassword.mail_api_token_password = false : d_showPassword.mail_api_token_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['50']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.mail_api_send_mail_uri">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['51']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.password_reset_mail_approve_frontend_uri_and_route[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['52']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.password_reset_mail_approve_subject[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['53']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.password_reset_mail_approve_link_text[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['79']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-textarea v-model="d_frontendData.mail_privacy_notice_text[lang.label]" rows="5"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['80']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.mail_smtp_address">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['81']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.mail_smtp_username">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['82']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.mail_smtp_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.mail_smtp_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.mail_smtp_password ? d_showPassword.mail_smtp_password = false : d_showPassword.mail_smtp_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card-body>


            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['waccount_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['54']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.waccount_external_key">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['55']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.waccount_url">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['56']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.waccount_username">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['57']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.waccount_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.waccount_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.waccount_password ? d_showPassword.waccount_password = false : d_showPassword.waccount_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['58']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.waccount_system_product_id">
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['support_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['63']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.support_details_subject[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['64']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.support_mail_from">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['65']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.support_mail_from_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.support_mail_from_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.support_mail_from_password ? d_showPassword.support_mail_from_password = false : d_showPassword.support_mail_from_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['66']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.support_details_link_text[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['67']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.support_details_frontend_uri_and_route[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['register_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['59']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.register_function">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['26'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['42']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.register_mail_approve_frontend_uri_and_route[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['43']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.register_mail_approve_subject[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['44']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frontendData.register_mail_approve_mail_from">
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['45']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-input-group>
                    <b-form-input :type="d_showPassword.register_mail_approve_mail_from_password ? 'text' : 'password'" class="form-control" v-model="d_frontendData.register_mail_approve_mail_from_password" :placeholder="StoreLangTranslation.data['please_fill'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="d_showPassword.register_mail_approve_mail_from_password ? d_showPassword.register_mail_approve_mail_from_password = false : d_showPassword.register_mail_approve_mail_from_password = true; $forceUpdate();">
                        <i class="fa fa-eye"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['46']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <input type="text" class="form-control" v-model="d_frontendData.register_mail_approve_link_text[lang.label]">
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
              <b-row>
                <b-col sm="12" lg="8">
                  <h6>
                    <i class="fa fa-calculator"></i>
                    {{ StoreLangTranslation.data['login_settings'][StoreLang] }}
                  </h6>
                </b-col>
                <b-col sm="12" lg="4" style="text-align: right;"></b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['27']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.login_function_external">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['27'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['26']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frontendData.login_function">
                    <option v-for="(op, op_index) in d_frdWdm['0']['parameters']['26'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>

              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ d_frdWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="4">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-textarea v-model="d_frontendData.login_slogan_text[lang.label]" rows="2"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              
              <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                <b-col sm="12" lg="4">
                  {{ StoreLangTranslation.data['login_background_image_by_link'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="6">
                  <input type="text" class="form-control" v-model="d_frontendData.login_background_image">
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button variant="outline-success" @click="f_imageUpload('login_background_image')">
                    <i class="fa fa-plus-circle"></i>
                  </b-button>
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button variant="outline-danger" @click="$delete(d_frontendData, 'login_background_image'); $forceUpdate();">
                    <i class="fa fa-minus-circle"></i>
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12" lg="4">
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_background_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_buttons_background_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_card_in_background_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_card_in_shadow_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_card_out_background_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_card_out_shadow_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_inputs_border_color">
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                    <b-col sm="12" lg="9">
                      {{ d_frdWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="3">
                      <input type="color" class="form-control" v-model="d_frontendData.login_loading_background_color">
                    </b-col>
                  </b-row>

                </b-col>
                <b-col sm="12" lg="8">
                  <b-card :style="d_fullScreenLogin()">
                    <b-card-header header-bg-variant="white" class="p-1">
                      <b-row>
                        <b-col cols="6"></b-col>
                        <b-col cols="6">
                          <b-button class="pull-right" size="lg" :variant="d_fullScreen ? 'warning' : 'white'" :style="d_fullScreen ? 'margin-right: 10px; padding: 0px; border: solid 2px green;' : 'margin-right: 10px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
                            <i class="fa fa-arrows-alt" title="Tam Ekran"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-header>
                      <b-row>
                        <b-col sm="12" lg="12">
                          <login :p_viewMode="'only_show'"></login>
                        </b-col>
                      </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
            <b-row>
              <b-col sm="12" lg="6">
              </b-col>
              <b-col sm="12" lg="6" style="text-align: right;">
                <template v-if="d_frontendLoading">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </template>
                <template v-else>
                  <b-button variant="outline-primary" @click="f_saveFrontendSettings()">
                    <i class="fa fa-save"></i>
                    {{ StoreLangTranslation.data['save'][StoreLang] }}
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-col>
        <b-col sm="12" lg="2"></b-col>
      </b-row>
    </template>
    <template v-if="d_page404Show">
      <Page404></Page404>
    </template>

    <b-modal v-if="d_imageUploadModal" id="d_imageUploadModal_show" v-model="d_imageUploadModal" scrollable hide-footer :title="StoreLangTranslation.data['image_upload'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
          <p>
            <b-row>
              <b-col sm="12" lg="12">
                <b-form-file
                    @input="f_addImageToDocuments()"
                    style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                    v-model="d_tmpFile"
                    :accept="f_createAcceptedDocumentTypes()"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                >
                  <i class="fa fa-plus"></i>
                </b-form-file>
              </b-col>
            </b-row>
          </p>
          <p>
            <template v-for="(doc, doc_index) in d_systemDocumentList">
              <b-row>
                <b-col sm="12" lg="12">
                  {{ doc }}
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="10">
                  <img :src="doc" width="300"> 
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button size="sm" style="margin-right: 5px;" variant="outline-success" @click="f_selectImage(doc)">
                    <i class="fa fa-plus"></i> {{ StoreLangTranslation.data['select_image'][StoreLang] }}
                  </b-button>            
                </b-col>
              </b-row>
              <hr>
            </template>
          </p>
        </b-modal>

  </div>
</template>

<script>

import { API_BASE_URL } from '@/config';
import { ServiceFunctions } from '@/wam/modules/service';
import { ClsWdm } from '@/wam/modules/wdm';
import store from '@/store';
import {
  default as Page404
} from '@/wam/components/widgets/Page404';
import {
  default as Login
} from '@/wam/views/login';
import { mapGetters } from 'vuex';
export default {
  name: 'frontend_settings',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreLang: 'StoreLang',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreLangList: 'StoreLangList',
  }),
  components: {
    Page404,
    Login,
  },
  props: {},
  data () {
    return {
      d_editParameter: '',
      d_systemDocumentList: [],
      d_imageUploadModal: false,
      d_fullScreen: false,
      d_tmpFile: null,
      d_showPassword: {
        'mail_smtp_password': false,
        'worganization_api_username': false,
        'worganization_api_password': false,
        'worganization_token': false,
        'register_mail_approve_mail_from_password': false,
        'mail_api_token_username': false,
        'mail_api_token_password': false,
        'waccount_password': false,
        'support_mail_from_password': false,
      },
      d_frdWdm: '',
      d_frontendData: {
        'application_name': {},
        'are_shop_pages_active': '',
        'login_background_color': '',
        'login_buttons_background_color': '',
        'login_card_in_background_color': '',
        'login_card_in_shadow_color': '',
        'login_card_out_background_color': '',
        'login_card_out_shadow_color': '',
        'login_function': '',
        'register_function': '',
        'login_function_external': '',
        'login_inputs_border_color': '',
        'login_loading_background_color': '',
        'login_slogan_text': {},
        'mail_api_send_mail_uri': '',
        'mail_api_token_password': '',
        'mail_api_token_uri': '',
        'mail_api_token_username': '',
        'menu_background_color': '',
        'menu_border_bottom_color': '',
        'menu_box_shadow_color': '',
        'menu_child_item_background_color': '',
        'menu_child_item_border_bottom_color': '',
        'menu_child_item_text_color': '',
        'navbar_application_name_color': '',
        'menu_parent_item_background_color': '',
        'menu_parent_item_border_bottom_color': '',
        'menu_parent_item_text_color': '',
        'menu_top_caption': {},
        'menu_type': '',
        'meta': '',
        'meta_value': '',
        'navbar_application_name_shadow_color': '',
        'navbar_background_color': '',
        'password_reset_mail_approve_frontend_uri_and_route': {},
        'password_reset_mail_approve_link_text': {},
        'password_reset_mail_approve_subject': {},
        'privacy_policy_link': {},
        'register_mail_approve_frontend_uri_and_route': {},
        'register_mail_approve_link_text': {},
        'register_mail_approve_mail_from': '',
        'register_mail_approve_mail_from_password': '',
        'register_mail_approve_subject': {},
        'mail_privacy_notice_text': {},
        'mail_smtp_address': '',
        'mail_smtp_username': '',
        'mail_smtp_password': '',
        'status': '',
        'terms_of_service_link': {},
        'wanalyzer_api_password': '',
        'wanalyzer_api_url': '',
        'wanalyzer_api_username': '',
        'wanalyzer_frontend_url': '',
        'wmanager_uri': '',
        'worganization_api_password': '',
        'worganization_api_url': '',
        'worganization_api_username': '',
        'worganization_frontend_url': '',
        'worganization_organizasyon_id_values': '',
        'worganization_token': '',
        'waccount_external_key': '',
        'waccount_url': '',
        'waccount_username': '',
        'waccount_password': '',
        'waccount_system_product_id': '',
        'worganization_user_id': '',
        'application_frontend_uri': '',
        'application_backend_uri': '',
        'support_details_subject': {},
        'support_mail_from': '',
        'support_mail_from_password': '',
        'support_details_link_text': {},
        'support_details_frontend_uri_and_route': {},
        'page_area_text_color': {},
        'footer_text_color': {},
        'footer_background_color': {},
        'navbar_text_color': {},
        'facebook_link': {},
        'twitter_link': {},
        'instagram_link': {},
        'linkedin_link': {},
        'login_background_image': {},
        'footer_date': '',
        'navbar_flowing_text': {},
        'footer_date': '',
        'footer_text_padding': '',
        'footer_text_size': '',
        'footer_text_weight': '',
        'general_font_select': '',
        'general_font_size': '',
        'general_font_style': '',
        'general_font_weight': '',
        'general_text_color': '',
        'language_options': [],
        'market_visiblity_type': '',
        'navbar_font_size': '',
        'navbar_text_weight': '',
        'page_favicon': '',
        'default_language': '',
        'language_change_button_visibility': '',
        'google_analytics_id': '',
        'login_button_name': {},
        'language_change_button_type': '',
      },
      d_user: '',
      d_eligiblePermissionList: ['admin'],
      d_pageShow: false,
      d_page404Show: false,
      d_frontendLoading: false,

    };
  },
  created: function () {
    let user_perm_eligible = false;
    ClsWdm.getLayerWdm('frd').then(resp_wdm_data => { this.d_frdWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.d_user && this.d_user.auth && this.d_user.auth.length > 0) {
      let det = false;
      for (let perm_index in this.d_eligiblePermissionList) {
        if (this.d_user.auth.indexOf(this.d_eligiblePermissionList[perm_index]) !== -1) {
          user_perm_eligible = true;
          this.d_pageShow = true;
          break;
        }
      }
    }
    if (user_perm_eligible) {
      this.f_langEligibleParameterProcess();
      // this.d_frontendData = JSON.parse(JSON.stringify(this.StoreFrontendSettings.data));
      this.f_getFrontendSettings();
      // console.log('this.d_frontendData.menu_type', this.d_frontendData.menu_type);
      // console.log(JSON.stringify(this.d_frontendData));
    } else {
      this.d_page404Show = true;
    }
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_selectImage: function (document_url) {
      this.d_frontendData[this.d_editParameter] = document_url;
      this.d_imageUploadModal = false;
      this.$forceUpdate();
    },
    f_imageUpload: function (selected_parameter_id) {
      this.d_editParameter = selected_parameter_id;
      let query = '';
      let data = {};
      this.d_systemDocumentList = [];
      ServiceFunctions.document_list(query, data)
        .then(resp => {
          if (resp.data.status_code === "11000") {
            let system_product_id = localStorage.getItem('system_product_id');
            for (let i in resp.data.list) {
              let document_url = API_BASE_URL + 'v1/document/get?__sys__pro__id=' + system_product_id + '&doc=' + resp.data.list[i];
              this.d_systemDocumentList.push(document_url);
            }
            this.d_imageUploadModal = true;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_addImageToDocuments: function () {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (["jpg", "jpeg", "png", "ico"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          // console.log('reader.result; ', reader.result);
          vm.f_documentRecord(reader.result);
          vm.$forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png", "ico"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_documentRecord: function (file_data) {
      let query = '';
      let data = {'file': file_data};
      ServiceFunctions.document_record(query, data)
        .then(resp => {
          if (resp.data.status_code === "11000") {
            let system_product_id = localStorage.getItem('system_product_id');
            let document_url = API_BASE_URL + 'v1/document/get?__sys__pro__id=' + system_product_id + '&doc=' + resp.data.document_name;
            // console.log('document_url: ', document_url);
            this.d_frontendData[this.d_editParameter] = document_url;
            this.$forceUpdate();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    d_fullScreenLogin: function () {
      if (this.d_fullScreen) {
        let height = window.innerHeight - 200;
        return 'overflow-y: auto; height: ' + height.toString() + 'px; top: 120px; left: 10px; right: 10px; position: fixed; z-index: 1000;';
      } else {
        return 'overflow-y: auto; overflow-x: auto; height: 500px; margin-right: 10px; margin-left: 10px;';
      }
    },
    f_addImageToLoginBackgroundAsBase64: function () {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          vm.d_frontendData.login_background_image = reader.result;
          vm.$forceUpdate();
          vm.f_applyFrontendSettings();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_addImageToPageInfoDetailAsBase64: function () {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          vm.d_frontendData.navbar_middle_logo = reader.result;
          vm.$forceUpdate();
          vm.f_applyFrontendSettings();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = ['application_name', 'login_slogan_text', 'menu_top_caption', 'password_reset_mail_approve_frontend_uri_and_route', 'password_reset_mail_approve_link_text', 'password_reset_mail_approve_subject', 'privacy_policy_link', 'register_mail_approve_frontend_uri_and_route', 'register_mail_approve_link_text', 'register_mail_approve_subject', 'terms_of_service_link', 'support_details_subject', 'support_details_link_text', 'support_details_frontend_uri_and_route', 'mail_privacy_notice_text', 'navbar_flowing_text', 'login_button_name'];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_frontendData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_frontendData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_getFrontendSettings: function () {
      let query = '';
      let data = {};
      this.d_frontendLoading = true;
      ServiceFunctions.frontend_get(query, data)
        .then(resp => {
          this.d_frontendLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_frontendData = resp.data.frontend;
            this.$store.commit('MutationFrontendSettings', { 'status': 1, 'data': this.d_frontendData });
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_saveFrontendSettings: function () {
      let query = '';
      let data = { 'frontend': this.d_frontendData };
      this.d_frontendLoading = true;
      ServiceFunctions.frontend_save(query, data)
        .then(resp => {
          this.d_frontendLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });

    },
    f_applyFrontendSettings: function () {
      // console.log('this.d_frontendData.menu_background_color : ', this.d_frontendData.menu_background_color);
      this.$store.commit('MutationFrontendSettings', { 'status': 1, 'data': JSON.parse(JSON.stringify(this.d_frontendData)) });
    }
  },
  watch: {
    'd_frontendData.menu_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_buttons_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_card_in_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_card_in_shadow_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_card_out_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_card_out_shadow_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_inputs_border_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_loading_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_border_bottom_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_box_shadow_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_child_item_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_child_item_border_bottom_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_child_item_text_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_top_caption': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_parent_item_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_parent_item_border_bottom_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_parent_item_text_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_application_name_shadow_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_application_name_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.are_shop_pages_active': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.page_area_text_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.footer_text_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.footer_background_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_text_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.facebook_link': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.twitter_link': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_middle_logo': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.instagram_link': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.linkedin_link': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.login_background_image': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.menu_type': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.footer_date': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_flowing_text': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.footer_text_padding': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.footer_text_size': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.footer_text_weight': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.general_font_select': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.general_font_size': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.general_font_style': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.general_font_weight': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.general_text_color': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.language_options': function () {
      let all_language_options = this.d_frdWdm['0']['parameters']['87']['options'];
      let use_lang_list = [];
      for (let lang of all_language_options) {
        if (this.d_frontendData.language_options.indexOf(lang.value) !== -1) {
          use_lang_list.push(lang);
        }
      }
      this.$store.commit('MutationLangList', { 'status': 1, 'data': use_lang_list });
      this.f_applyFrontendSettings();
    },
    'd_frontendData.market_visiblity_type': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_font_size': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.navbar_text_weight': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.page_favicon': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.default_language': function () {
      this.f_applyFrontendSettings();
    },
    'd_frontendData.language_change_button_visibility': function () {
      this.f_applyFrontendSettings();
    },
  }
};

</script>

